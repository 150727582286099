import { UnreachableError } from "base/preconditions";
import { action, makeObservable } from "mobx";
import { Messages } from "pages/build/page/build_page.messages";
import { BuildPageStore } from "pages/build/page/build_page_store";
import { BuildPageType } from "pages/build/page/types";
import { CustomerService } from "services/customer/customer_service";
import { ProductService } from "services/product/product_service";

export class BuildPagePresenter {
  constructor(
    private readonly productService: ProductService,
    private readonly customerService: CustomerService
  ) {
    makeObservable(this);
  }

  @action
  closeNotification(store: BuildPageStore) {
    store.notification = undefined;
  }

  @action
  async buildList(store: BuildPageStore, page: BuildPageType, token: string) {
    try {
      store.error = undefined;
      store.loading = true;
      switch (page) {
        case "customer":
          await this.customerService.buildCustomerList({ token });
          break;
        case "product":
          await this.productService.buildItemList({ token });
          break;
        default:
          throw new UnreachableError(page);
      }
      store.notification = {
        type: "success",
        message: Messages.successMessage(page),
      };
      store.loading = false;
    } catch (e) {
      store.notification = {
        type: "error",
        message: Messages.errorMessage(page),
      };
    }
  }
}
