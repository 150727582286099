import { AuthService, LoginReq, SignupReq } from "services/auth/auth_service";
import { User } from "services/auth/types";
import { ExpiredSessionError } from "services/errors/expired_session_error";
import { LoginError } from "services/errors/login_error";
import { HttpService } from "services/http/http_service";

export class HttpAuthService implements AuthService {
  constructor(private readonly httpService: HttpService) {}

  async login(req: LoginReq): Promise<User | undefined> {
    try {
      const user = await this.httpService.post<LoginReq, User>(
        "/login",
        undefined,
        req
      );
      return user;
    } catch (e) {
      // HACK: Expired Sessions also return 401 response status. When
      // logging in however incorrect credentials will also return a 401
      // so we have to override it here
      if (e instanceof ExpiredSessionError) {
        throw new LoginError();
      }
      throw e;
    }
  }

  async signup(req: SignupReq): Promise<User> {
    return await this.httpService.post<SignupReq, User>(
      "/signup",
      undefined,
      req
    );
  }
}
