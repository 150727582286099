import { RequestWithAuth } from "services/base";
import { HttpService } from "services/http/http_service";
import {
  GetItemListReq,
  GetItemListResponse,
  GetItemReq,
  GetItemResponse,
  ProductService,
} from "services/product/product_service";

const BASE_PREFIX = "/product";

export class HttpProductService implements ProductService {
  constructor(private readonly httpService: HttpService) {}

  async getItemList(
    req: RequestWithAuth<GetItemListReq>
  ): Promise<GetItemListResponse> {
    const { filter, token } = req;
    const params = new URLSearchParams();
    filter &&
      Object.entries(filter).forEach(([key, val]) => {
        if (val) {
          params.append(key, val);
        }
      });
    return this.httpService.get(`${BASE_PREFIX}?${params.toString()}`, token);
  }

  async getItem(req: RequestWithAuth<GetItemReq>): Promise<GetItemResponse> {
    const { itemId, token } = req;
    return await this.httpService.get(`${BASE_PREFIX}/${itemId}`, token);
  }

  async buildItemList(req: RequestWithAuth): Promise<void> {
    const { token } = req;
    return this.httpService.post(`${BASE_PREFIX}:build`, token);
  }
}
