import { observer } from "mobx-react";
import { OrdersPage } from "pages/orders/page/orders_page";
import { OrdersPagePresenter } from "pages/orders/page/orders_page_presenter";
import { OrdersPageStore } from "pages/orders/page/orders_page_store";
import React from "react";
import { HttpClient } from "services/http/http_client";
import { HttpOrderService } from "services/order/http_order_service";
import { OrderStore } from "services/order/order_store";

export type OrdersPageProps = {
  orderStore: OrderStore;
  fetchCustomers: () => Promise<void>;
};

export const installOrdersPage = (): {
  OrdersPage: React.ComponentType<OrdersPageProps>;
} => {
  const orderService = new HttpOrderService(new HttpClient());

  const store = new OrdersPageStore();
  const presenter = new OrdersPagePresenter(orderService);

  const OrdersPageImpl = observer(
    ({ orderStore, fetchCustomers }: OrdersPageProps) => (
      <OrdersPage
        customers={orderStore.customerList}
        fetchCustomers={fetchCustomers}
        ordersPageStore={store}
        ordersPagePresenter={presenter}
      />
    )
  );

  return { OrdersPage: OrdersPageImpl };
};
