import { Carousel } from "ui/base/carousel/carousel";
import { Media } from "ui/base/media/media";

export const MediaCarousel = ({
  mediaUrls,
  alt,
}: {
  mediaUrls: string[];
  // Alt text to be rendered if no media URLs are provided
  alt: string;
}) => {
  return mediaUrls.length ? (
    <Carousel>
      {mediaUrls.map((url, i) => (
        <Media src={url} alt={url} key={i} />
      ))}
    </Carousel>
  ) : (
    <Media src="" alt={alt} />
  );
};
