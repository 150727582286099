import { IconButton, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export const NumberInput = ({
  value = 0,
  minValue = 0,
  maxValue = Infinity,
  step = 1,
  onChange,
  disabled,
  size = "medium",
}: {
  value?: number;
  minValue?: number;
  maxValue?: number;
  step?: number;
  onChange?: (val: number) => void;
  disabled?: boolean;
  size?: "small" | "medium";
}) => {
  const handleOnChange = (val: number) => {
    onChange?.(Math.min(Math.max(val, minValue), maxValue));
  };

  const widths: Record<"small" | "medium", number> = {
    small: 80,
    medium: 120,
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <IconButton
        disabled={disabled}
        onClick={() => handleOnChange(value - step)}
      >
        <RemoveIcon fontSize="inherit" />
      </IconButton>
      <TextField
        type="number"
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          min: minValue,
          max: maxValue,
          step: step,
        }}
        value={value}
        onChange={(e) => {
          const val = Number(e.target.value);
          handleOnChange(val);
        }}
        size="small"
        sx={{ width: widths[size] }}
        disabled={disabled}
      />
      <IconButton
        disabled={disabled}
        onClick={() => handleOnChange(value + step)}
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};
