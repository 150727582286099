import { computed, makeObservable, observable } from "mobx";
import { Order } from "services/order/types";
import { Dayjs } from "dayjs";

export class OrdersPageStore {
  constructor() {
    makeObservable(this);
  }

  @observable.ref
  orders: Order[] = [];

  @observable.ref
  createdBy: string = "";

  @observable.ref
  customer: string = "";

  @observable.ref
  fromDate: Dayjs | null = null;

  @observable.ref
  toDate: Dayjs | null = null;

  @observable.ref
  loading: boolean = false;

  @observable.ref
  downloadLoading: boolean = false;

  @observable.ref
  confirmModalOpen: boolean = false;

  @computed
  get confirmModalText() {
    const baseText = `Confirm downloading a summary of ${this.orders.length} orders`;
    const fromText = this.fromDate
      ? `from ${this.fromDate.format("DD/MM/YYYY")}`
      : "";
    const toText = this.toDate ? `to ${this.toDate.format("DD/MM/YYYY")}` : "";
    return `${[baseText, fromText, toText].join(" ")}?`;
  }
}
