import styles from "./picture.module.css";

export const Picture = ({
  imageUrl,
  alt,
  onError,
}: {
  imageUrl: string | undefined;
  alt: string;
  onError?: () => void;
}) => {
  return (
    <img src={imageUrl} alt={alt} className={styles.image} onError={onError} />
  );
};
