import { Logo } from "ui/base/logo/logo";
import styles from "./error_page.module.css";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <Logo size="small" />
      <Typography variant="h5" sx={{ fontWeight: 700 }}>
        Oops, something went wrong...
      </Typography>
      <Button variant="contained" onClick={() => navigate("/")}>
        Go back home
      </Button>
    </div>
  );
};
