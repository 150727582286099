import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Alert,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Role } from "services/auth/types";
import { LoadingButton } from "ui/base/loading_button/loading_button";
import { Logo } from "ui/base/logo/logo";
import styles from "./auth_page.module.css";
import {
  AuthPagePresenter,
  AuthPageStore,
} from "pages/auth/page/auth_page_store";
import { observer } from "mobx-react";
import { useAppContext } from "routes/app_context";

type AuthPageProps = {
  type: "login" | "signup";
  store: AuthPageStore;
  presenter: AuthPagePresenter;
};

export const AuthPage = observer(
  ({ type, store, presenter }: AuthPageProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAppContext();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [userTriggered, setUserTriggered] = useState<boolean>(false);

    React.useEffect(() => {
      if (new URLSearchParams(location.search).has("sessionExpired")) {
        setError("Your session has expired. Please log in again.");
      }
    }, [location.search]);

    // Tries to redirect the user to the last location they were at prior to being redirected
    // to the login page instead of always redirecting them home.
    const redirectToOrigin = useCallback(() => {
      const origin = location.state?.from?.pathname || "/";
      navigate(origin);
    }, [location.state?.from?.pathname, navigate]);

    React.useEffect(() => {
      // We need to check if it is a userTriggered action to make sure
      // that we don't get into infinite loops when the user state gets
      // refreshed due to delays in the user state update via React Context
      if (userTriggered && user) {
        redirectToOrigin();
      }
    }, [user, userTriggered, redirectToOrigin]);

    const actionLabel = type === "login" ? "Login" : "Create New User";

    return (
      // Have to manually override display block of container to flex via sx instead of CSS
      <Container maxWidth="sm" className={styles.page} sx={{ display: "flex" }}>
        {type === "login" && (
          <div className={styles.logo}>
            <Logo />
          </div>
        )}

        <Typography variant="h5" align="left" sx={{ fontWeight: 700 }}>
          {actionLabel}
        </Typography>

        {type === "signup" && (
          <TextField
            required={true}
            label="Full Name"
            value={store.fullname}
            onChange={(e) => presenter.setFullname(store, e.target.value)}
          />
        )}

        <TextField
          required={true}
          label="Username"
          value={store.username}
          autoComplete={type === "signup" ? "off" : "on"}
          onChange={(e) => presenter.setUsername(store, e.target.value)}
        />

        <FormControl fullWidth={true} variant="outlined" required={true}>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={store.password}
            onChange={(e) => presenter.setPassword(store, e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {type === "signup" && (
          <FormControl fullWidth={true}>
            <InputLabel>Role</InputLabel>
            <Select
              label="Role"
              value={store.role}
              onChange={(e) => {
                presenter.setRole(store, e.target.value as Role);
              }}
            >
              <MenuItem value={Role.User}>User</MenuItem>
              <MenuItem value={Role.Admin}>Admin</MenuItem>
              <MenuItem value={Role.Guest}>Guest</MenuItem>
              <MenuItem value={Role.AdminViewOldEmpty}>
                Admin View Old Empty
              </MenuItem>
            </Select>
          </FormControl>
        )}

        {error && <Alert severity="error">{error}</Alert>}

        <LoadingButton
          loadingLabel="Loading..."
          loading={loading}
          onClick={async () => {
            setError("");
            setUserTriggered(true);
            setLoading(true);
            try {
              await presenter.action(store);
            } catch (e) {
              // Purely for TS typecheck to be happy
              if (e instanceof Error) {
                setError(e.message);
              }
              console.error(e);
            } finally {
              setLoading(false);
            }
          }}
        >
          {actionLabel}
        </LoadingButton>
      </Container>
    );
  }
);
