import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./orders_page.module.css";
import { formatRupiah } from "ui/utils/currency";
import { calculateOrderTotal } from "services/order/utils";
import { Download } from "@mui/icons-material";
import { OrdersPageStore } from "pages/orders/page/orders_page_store";
import { observer } from "mobx-react";
import { LoadingPage } from "pages/loading/loading_page";
import { OrdersPagePresenter } from "pages/orders/page/orders_page_presenter";
import { Order } from "services/order/types";
import { OrderGenerator } from "services/order/order_generator";
import { Role } from "services/auth/types";
import { InvoiceGenerator } from "services/order/invoice_generator";
import { useAppContext } from "routes/app_context";
import { useErrorHandler } from "react-error-boundary";

const OrderTableRow = ({ order }: { order: Order }) => {
  return (
    <TableRow>
      <TableCell>{order.id}</TableCell>
      <TableCell>{order.customer}</TableCell>
      <TableCell>{order.createdBy}</TableCell>
      <TableCell>
        {new Date(order.createdAt).toLocaleDateString("id-ID")}
      </TableCell>
      <TableCell>{formatRupiah(calculateOrderTotal(order))}</TableCell>
      <TableCell align="center">
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <Button
            variant="contained"
            fullWidth={true}
            startIcon={<Download />}
            onClick={() => InvoiceGenerator.download(order)}
          >
            Invoice
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth={true}
            startIcon={<Download />}
            onClick={() => OrderGenerator.downloadCsv([order])}
          >
            CSV
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const OrdersPage = observer(
  ({
    customers,
    fetchCustomers,
    ordersPageStore,
    ordersPagePresenter,
  }: {
    customers: string[];
    fetchCustomers: () => Promise<void>;
    ordersPageStore: OrdersPageStore;
    ordersPagePresenter: OrdersPagePresenter;
  }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useAppContext();
    const handleError = useErrorHandler();
    const { orders, fromDate, toDate } = ordersPageStore;

    const fetchOrders = useCallback(() => {
      ordersPagePresenter
        .getOrders(ordersPageStore, user?.token ?? "")
        .catch((e) => handleError(e));
    }, [handleError, ordersPagePresenter, ordersPageStore, user?.token]);

    useEffect(() => {
      fetchOrders();
    }, [fetchOrders]);

    return (
      <>
        <Typography variant="h3" sx={{ fontWeight: 700 }}>
          Orders
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 16,
          }}
        >
          <div className={styles.controls}>
            {user?.role === Role.Admin && (
              <TextField
                label="Dibuat oleh"
                value={ordersPageStore.createdBy}
                onChange={(e) =>
                  ordersPagePresenter.setCreatedBy(
                    ordersPageStore,
                    e.target.value
                  )
                }
              />
            )}
            <Autocomplete
              options={customers}
              // Only make the get customers call when users interact with the select
              onOpen={async () => {
                ordersPagePresenter.setCustomer(ordersPageStore, "");
                if (customers.length === 0) {
                  setLoading(true);
                  await fetchCustomers();
                  setLoading(false);
                }
              }}
              onChange={(_, value) => {
                value &&
                  ordersPagePresenter.setCustomer(ordersPageStore, value);
              }}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nama Toko"
                  sx={{ minWidth: "300px" }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <DatePicker
              label="Dari Tanggal"
              value={fromDate}
              onChange={(newValue) => {
                ordersPagePresenter.setFromDate(ordersPageStore, newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="DD/MM/YYYY"
              disableFuture={true}
            />
            <DatePicker
              label="Ke Tanggal"
              value={toDate}
              onChange={(newValue) => {
                ordersPagePresenter.setToDate(ordersPageStore, newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="DD/MM/YYYY"
              minDate={fromDate}
              disableFuture={true}
            />
          </div>
          <div className={styles.controls}>
            <Button variant="contained" onClick={fetchOrders}>
              Apply Filter
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                ordersPagePresenter.setConfirmModalOpen(ordersPageStore, true);
              }}
            >
              Download Order Summary
            </Button>
          </div>
        </div>
        {ordersPageStore.loading ? (
          <LoadingPage />
        ) : (
          <TableContainer>
            <Table stickyHeader={true}>
              <TableHead sx={{ backgroundColor: "red" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Toko</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Salesperson</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Tanggal</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: 700 }} align="center">
                    Download
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, i) => (
                  <OrderTableRow key={i} order={order} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Modal
          open={ordersPageStore.confirmModalOpen}
          onClose={() => {
            ordersPagePresenter.setConfirmModalOpen(ordersPageStore, false);
          }}
        >
          <Card className={styles.modal}>
            <CardContent>
              <Typography variant="h5" fontWeight={700} gutterBottom={true}>
                Download Order Summary
              </Typography>
              <Typography variant="body1">
                {ordersPageStore.confirmModalText}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                onClick={() => {
                  ordersPagePresenter.downloadOrderSummary(ordersPageStore);
                }}
              >
                <div className={styles.btnContent}>
                  {ordersPageStore.downloadLoading && (
                    <CircularProgress color="secondary" size={24} />
                  )}
                  {!ordersPageStore.downloadLoading
                    ? "Confirm"
                    : "Generating order summary ..."}
                </div>
              </Button>
              <Button
                color="error"
                onClick={() => {
                  ordersPagePresenter.setConfirmModalOpen(
                    ordersPageStore,
                    false
                  );
                }}
              >
                Cancel
              </Button>
            </CardActions>
          </Card>
        </Modal>
      </>
    );
  }
);
