import { Picture } from "ui/base/picture/picture";
import logo from "./STC_transparent.png";
import styles from "./logo.module.css";
import classNames from "classnames";

export const Logo = ({
  size = "medium",
}: {
  size?: "small" | "medium" | "large";
}) => (
  <div
    className={classNames({
      [styles.small]: size === "small",
      [styles.medium]: size === "medium",
      [styles.large]: size === "large",
    })}
  >
    <Picture imageUrl={logo} alt="STC Logo" />
  </div>
);
