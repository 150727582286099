import { UnreachableError } from "base/preconditions";
import { DefaultError } from "services/errors/default_error";
import { ExpiredSessionError } from "services/errors/expired_session_error";
import { NotFoundError } from "services/errors/not_found_error";
import { HttpService } from "services/http/http_service";

const LOCAL_BASE_URL = "http://127.0.0.1:5000";
const PROD_BASE_URL = "https://limitless-lake-59518.herokuapp.com";

type BaseRequestArgs = {
  endpoint: string;
  token?: string;
};

type RequestArgs<Request> = BaseRequestArgs &
  (
    | { type: "GET" }
    | {
        type: "POST" | "PUT";
        body?: Request;
      }
  );

export class HttpClient implements HttpService {
  async get<Response>(endpoint: string, token?: string): Promise<Response> {
    return this.sendRequest<{}, Response>({ type: "GET", endpoint, token });
  }

  async post<Request, Response>(
    endpoint: string,
    token?: string,
    body?: Request
  ): Promise<Response> {
    return this.sendRequest<Request, Response>({
      type: "POST",
      endpoint,
      token,
      body,
    });
  }

  async put<Request, Response>(
    endpoint: string,
    token?: string,
    body?: Request
  ): Promise<Response> {
    return this.sendRequest<Request, Response>({
      type: "PUT",
      endpoint,
      token,
      body,
    });
  }

  private async sendRequest<Request, Response>(
    req: RequestArgs<Request>
  ): Promise<Response> {
    let response;
    switch (req.type) {
      case "GET":
        response = await fetch(PROD_BASE_URL + req.endpoint, {
          headers: {
            Authorization: `Bearer ${req.token}`,
          },
        });
        break;
      case "POST":
      case "PUT":
        response = await fetch(PROD_BASE_URL + req.endpoint, {
          method: req.type,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${req.token}`,
          },
          body: JSON.stringify(req.body),
        });
        break;
      default:
        throw new UnreachableError(req);
    }

    // Process Status Codes
    if (response.status === 401) {
      throw new ExpiredSessionError();
    } else if (response.status === 404) {
      throw new NotFoundError();
    } else if (response.status !== 200) {
      throw new DefaultError();
    }

    const resJson = await response.json();
    return resJson.data;
  }
}
