export const enum Unit {
  DZ = "DZ",
  PC = "PC",
  BX = "BX",
}

export type Item = {
  /** Name of the item */
  title: string;
  /** Unique ID of the item */
  itemId: string;
  /** Actual ID to display to the admin */
  displayId: string;
  /** Units in which the items are sold */
  unit: Unit;
  /** Price per unit of the Item */
  unitPrice?: number;
  /** Number of units in a pack */
  packing: number;
  /** Cover Image URL of the product */
  imageUrl?: string;
  /** Media URLs of the image */
  mediaObjs: string[];
  /** Image URLs to retry for a product */
  alternateImageUrls: string[];
  /** Category that the item is a part of */
  category?: string;
};

export const enum SortBy {
  NONE = "",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export type Filter = {
  search?: string;
  category?: string;
  sortBy?: SortBy;
};
