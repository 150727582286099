import { installAuthPage } from "pages/auth/page/install";
import { installCatalogPage } from "pages/catalog/page/install";
import React from "react";
import { installProductPage } from "pages/product/page/install";
import { installRoutes } from "routes/install";
import { installBuildPage } from "pages/build/page/install";
import { installOrdersPage } from "pages/orders/page/install";

export const installApp = (): { App: React.ComponentType } => {
  const { AuthPage } = installAuthPage();
  const { CatalogPage, clearCachedItemList } = installCatalogPage();
  const { BuildPage } = installBuildPage({ clearCachedItemList });
  const { ProductPage } = installProductPage();
  const { OrdersPage } = installOrdersPage();

  const Routes = installRoutes({
    AuthPage,
    BuildPage,
    CatalogPage,
    ProductPage,
    OrdersPage,
  });

  return { App: () => <Routes /> };
};
