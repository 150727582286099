import { RequestWithAuth } from "services/base";
import {
  CustomerService,
  GetCustomersResponse,
} from "services/customer/customer_service";
import { HttpService } from "services/http/http_service";

const BASE_PREFIX = "/customer";

export class HttpCustomerService implements CustomerService {
  constructor(private readonly httpService: HttpService) {}

  getCustomers(req: RequestWithAuth): Promise<GetCustomersResponse> {
    const { token } = req;
    return this.httpService.get(`${BASE_PREFIX}`, token);
  }

  buildCustomerList(req: RequestWithAuth): Promise<void> {
    const { token } = req;
    return this.httpService.post(`${BASE_PREFIX}:build`, token);
  }
}
