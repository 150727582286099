export const Video = ({
  src,
  onError,
}: {
  src: string;
  onError?: () => void;
}) => {
  return (
    <video width="100%" controls onError={onError}>
      <source src={src} type="video/mp4"></source>
    </video>
  );
};
