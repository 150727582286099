import { action, makeObservable } from "mobx";
import { OrderFormStore } from "pages/product/order_form/order_form_store";

export class OrderFormPresenter {
  constructor() {
    makeObservable(this);
  }

  @action
  setCartonQty(store: OrderFormStore, qty: number) {
    store.orderItem.cartonQty = qty;
  }

  @action
  setDiscountRp(store: OrderFormStore, disc: number) {
    store.orderItem.discountRp = disc;
  }

  @action
  setDiscountPercent(store: OrderFormStore, disc: number) {
    store.orderItem.discountPercent = disc;
  }

  @action
  setNotes(store: OrderFormStore, notes: string) {
    store.orderItem.notes = notes;
  }
}
