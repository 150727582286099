import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./product_page.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { OrderItem } from "services/order/types";
import { OrderFormProps } from "pages/product/order_form/install";
import { Toast, Notification } from "ui/base/toast/toast";
import { MediaCarousel } from "ui/base/carousel/media_carousel";
import { Role } from "services/auth/types";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "routes/app_context";

export const ProductPage = ({
  orderItem,
  OrderForm,
  addToCart,
}: {
  orderItem: OrderItem;
  OrderForm: React.ComponentType<OrderFormProps>;
  addToCart?: (orderItem: OrderItem) => void;
}) => {
  const [notification, setNotification] = useState<Notification | undefined>();
  const { user } = useAppContext();
  const navigate = useNavigate();

  const { title, displayId, mediaObjs } = orderItem.item;

  return (
    <>
      <div>
        <Button
          variant="text"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </div>
      <div className={styles.itemDetails}>
        <div className={styles.image}>
          <MediaCarousel mediaUrls={mediaObjs} alt={title} />
        </div>
        <div className={styles.productDetails}>
          <Typography variant="subtitle1">{displayId}</Typography>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            {title}
          </Typography>
          {user && user.role !== Role.Guest && (
            <div className={styles.orderForm}>
              <OrderForm
                orderItem={orderItem}
                addToCart={(orderItem: OrderItem) => {
                  addToCart?.(orderItem);
                  setNotification({
                    type: "success",
                    message: `${orderItem.item.title} added to cart.`,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
      {notification && (
        <Toast
          notification={notification}
          open={notification !== undefined}
          onClose={() => setNotification(undefined)}
        />
      )}
    </>
  );
};
