import { computed, makeObservable, observable } from "mobx";
import { Item, SortBy } from "services/product/types";

export type SelectOption<T> = {
  value: T;
  label: string;
};

export class CatalogPageStore {
  @observable.ref
  items: Item[] = [];

  @observable.ref
  filteredItems: Item[] = [];

  @observable.ref
  searchFilter: string = "";

  @observable.ref
  categoryFilter: string = "";

  @observable.ref
  sortBy: SortBy = SortBy.NONE;

  @observable.ref
  numItemsDisplay: number = 33;

  @observable.ref
  pageNumber: number = 1;

  @observable.ref
  isLoading?: boolean;

  sortOptions = [
    { label: "None", value: SortBy.NONE },
    { label: "Price: Low to High", value: SortBy.PRICE_ASC },
    { label: "Price: High to Low", value: SortBy.PRICE_DESC },
    { label: "Name: A to Z", value: SortBy.NAME_ASC },
    { label: "Name: Z to A", value: SortBy.NAME_DESC },
  ];

  @computed
  get startIndex(): number {
    return (this.pageNumber - 1) * this.numItemsDisplay + 1;
  }

  @computed
  get endIndex(): number {
    return Math.min(
      this.startIndex + this.numItemsDisplay - 1,
      this.filteredItems.length
    );
  }

  /**
   * Items to be displayed on the page
   */
  @computed
  get displayItems(): Item[] {
    return this.filteredItems.slice(this.startIndex - 1, this.endIndex);
  }

  /**
   * Number of pages that the items are paginated across
   */
  @computed
  get pages(): number {
    return Math.ceil(this.filteredItems.length / this.numItemsDisplay);
  }

  @computed
  get categories(): SelectOption<string>[] {
    const categorySet = new Set<string>();
    for (const item of this.items) {
      if (item.category) {
        categorySet.add(item.category);
      }
    }
    const categories = Array.from(categorySet)
      .sort()
      .map((val) => ({
        value: val,
        label: val,
      }));
    const allCategory = { value: "", label: "Select Catalog" };
    const noCategory = { value: "UNCATEGORISED", label: "Uncategorised" };
    return [allCategory, ...categories, noCategory];
  }

  constructor() {
    makeObservable(this);
  }
}
