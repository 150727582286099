import { RequestWithAuth } from "services/base";
import { HttpService } from "services/http/http_service";
import {
  CreateOrderReq,
  GetOrdersReq,
  GetOrdersResponse,
  OrderService,
} from "services/order/order_service";

const BASE_PREFIX = "/order";

export class HttpOrderService implements OrderService {
  constructor(private readonly httpService: HttpService) {}

  async getOrders(
    req: RequestWithAuth<GetOrdersReq>
  ): Promise<GetOrdersResponse> {
    const { createdBy, customer, fromDate, toDate, token } = req;
    const params = new URLSearchParams();
    createdBy && params.append("createdBy", createdBy);
    customer && params.append("customer", customer);
    fromDate && params.append("fromDate", fromDate);
    toDate && params.append("toDate", toDate);

    return this.httpService.get(`${BASE_PREFIX}?${params.toString()}`, token);
  }

  async createOrder(req: RequestWithAuth<CreateOrderReq>): Promise<void> {
    const { order, token } = req;
    return this.httpService.post(BASE_PREFIX, token, order);
  }
}
