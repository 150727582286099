import { Button, CircularProgress } from "@mui/material";
import styles from "./loading_button.module.css";

export const LoadingButton = ({
  children,
  loadingLabel,
  loading,
  onClick,
  variant = "contained",
  fullWidth,
  disabled,
}: {
  children: React.ReactNode;
  loadingLabel?: string;
  loading?: boolean;
  onClick?: () => void;
  variant?: "contained" | "text" | "outlined";
  fullWidth?: boolean;
  disabled?: boolean;
}) => (
  <Button
    variant={variant}
    onClick={onClick}
    fullWidth={fullWidth}
    disabled={disabled}
  >
    <div className={styles.btnContent}>
      {loading && <CircularProgress color="secondary" size={24} />}
      {!loading ? children : loadingLabel}
    </div>
  </Button>
);
