import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { OrderRow } from "pages/cart/order_row/order_row";
import React, { useState, useMemo } from "react";
import styles from "./cart_page.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UnstyledLink } from "ui/base/link/unstyled_link";
import { NumberInput } from "ui/base/number_input/number_input";
import { observer } from "mobx-react-lite";
import { OrderStore } from "services/order/order_store";
import { OrderPresenter } from "services/order/order_presenter";
import { Toast, Notification } from "ui/base/toast/toast";
import { formatRupiah } from "ui/utils/currency";
import { Item } from "services/product/types";
import { InvoiceGenerator } from "services/order/invoice_generator";
import { useAppContext } from "routes/app_context";
import { debounce } from "lodash";
import { useErrorHandler } from "react-error-boundary";

export const CartPage = observer(
  ({
    orderStore,
    orderPresenter,
  }: {
    orderStore: OrderStore;
    orderPresenter: OrderPresenter;
  }) => {
    const { user } = useAppContext();
    const handleError = useErrorHandler();

    const [notification, setNotification] =
      useState<Notification | undefined>();
    const [customerError, setCustomerError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const { customer, orderItems, discountRp, discountPercent, notes } =
      orderStore.order;

    const handleConfirmOrder = async () => {
      if (!customer) {
        setCustomerError("Nama Toko perlu diisi.");
        return;
      }

      try {
        await orderPresenter.confirmOrder(
          orderStore,
          user?.fullname ?? "",
          user?.token ?? ""
        );
      } catch (e) {
        handleError(e);
      }

      setNotification({
        type: "success",
        message: "Order confirmed and submitted.",
      });

      await InvoiceGenerator.download(orderStore.order);

      orderPresenter.clearOrder(orderStore, user?.token ?? "");
    };

    const handleOpenCustomerList = async () => {
      try {
        orderPresenter.setCustomer(orderStore, "", user?.token ?? "");
        if (orderStore.customerList.length === 0) {
          setLoading(true);
          await orderPresenter.getCustomers(orderStore, user?.token ?? "");
          setLoading(false);
        }
      } catch (e) {
        handleError(e);
      }
    };

    const debouncedSendUpdate = useMemo(() => {
      return debounce(() => {
        orderPresenter
          .sendCartUpdate(orderStore, user?.token ?? "")
          .catch((e) => handleError(e));
      }, 1000);
    }, [orderPresenter, orderStore, user?.token, handleError]);

    return (
      <>
        <UnstyledLink to="/">
          <Button variant="text" startIcon={<ArrowBackIcon />}>
            Back to Catalog
          </Button>
        </UnstyledLink>
        <div className={styles.content}>
          <div className={styles.cart}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 700 }}>
                Your Cart
              </Typography>
              <div className={styles.cartDetails}>
                <Typography variant="body1">
                  {orderStore.numCartons} CTN(s)
                </Typography>
                <Typography variant="body1">
                  {orderItems.length} Item(s)
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  disabled={orderItems.length === 0}
                  onClick={() =>
                    orderPresenter.clearItems(orderStore, user?.token ?? "")
                  }
                >
                  Clear Cart
                </Button>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              {orderItems.length ? (
                orderItems.map((orderItem, i) => (
                  <React.Fragment key={i}>
                    <OrderRow
                      orderItem={orderItem}
                      onRemoveItem={() => {
                        orderPresenter.removeItem(
                          orderStore,
                          orderItem.item.itemId,
                          user?.token ?? ""
                        );
                        setNotification({
                          type: "success",
                          message: `${orderItem.item.title} removed from cart.`,
                        });
                      }}
                      updateItemInCart={(item: Item, quantity: number) =>
                        orderPresenter.updateQuantity(
                          orderStore,
                          item,
                          quantity,
                          user?.token ?? ""
                        )
                      }
                    />
                    <Divider />
                  </React.Fragment>
                ))
              ) : (
                <Typography variant="body1" align="center">
                  Your cart is empty.
                </Typography>
              )}
            </div>
          </div>
          <div className={styles.summary}>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              Summary
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <div className={styles.lineItem}>
                <Typography>Subtotal</Typography>
                <Typography>{formatRupiah(orderStore.subtotal)}</Typography>
              </div>
              <div className={styles.lineItem}>
                <Typography>Pot. Harga (Rp)</Typography>
                <NumberInput
                  value={discountRp}
                  onChange={(val) => {
                    orderPresenter.setDiscountRp(orderStore, val);
                    debouncedSendUpdate();
                  }}
                  step={1000}
                  maxValue={orderStore.subtotal}
                  disabled={orderStore.subtotal === 0}
                />
              </div>
              <div className={styles.lineItem}>
                <Typography>Diskon (%)</Typography>
                <NumberInput
                  value={discountPercent}
                  maxValue={100}
                  onChange={(val) => {
                    orderPresenter.setDiscountPercent(orderStore, val);
                    debouncedSendUpdate();
                  }}
                  disabled={orderStore.subtotal === 0}
                />
              </div>
            </div>
            <Divider />
            <div className={styles.lineItem}>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                Total
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {formatRupiah(orderStore.total)}
              </Typography>
            </div>
            <Divider />
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              Detail Customer
            </Typography>
            <Autocomplete
              options={orderStore.customerList}
              // Only make the get customers call when users interact with the select
              onOpen={handleOpenCustomerList}
              onChange={(_, value) => {
                setCustomerError("");
                value &&
                  orderPresenter.setCustomer(
                    orderStore,
                    value,
                    user?.token ?? ""
                  );
              }}
              value={customer}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nama Toko"
                  required={true}
                  error={customerError !== ""}
                  helperText={customerError}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <TextField
              label="Notes"
              minRows={3}
              multiline={true}
              value={notes}
              onChange={(e) => {
                orderPresenter.setNotes(orderStore, e.target.value);
                debouncedSendUpdate();
              }}
            />
            <Button
              variant="contained"
              onClick={handleConfirmOrder}
              disabled={orderItems.length === 0}
            >
              Confirm Order
            </Button>
          </div>
        </div>
        {notification && (
          <Toast
            notification={notification}
            open={notification !== undefined}
            onClose={() => setNotification(undefined)}
          />
        )}
      </>
    );
  }
);
