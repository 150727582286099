import { action, makeObservable, runInAction } from "mobx";
import { CatalogPageStore } from "pages/catalog/page/catalog_page_store";
import { ProductService } from "services/product/product_service";
import { Filter, SortBy } from "services/product/types";

export class CatalogPagePresenter {
  constructor(private readonly productService: ProductService) {
    makeObservable(this);
  }

  @action
  async getItems(
    store: CatalogPageStore,
    token: string,
    isFiltering?: boolean
  ) {
    // Items in the catalog will only change when build product list is called
    // This means we don't need to make another request every time the page loads
    if (!isFiltering && store.items.length > 0) {
      return;
    }

    const filters: Filter = {
      search: store.searchFilter,
      category: store.categoryFilter,
      sortBy: store.sortBy,
    };
    runInAction(() => {
      store.isLoading = true;
    });
    const { products } = await this.productService.getItemList({
      filter: filters,
      token,
    });
    runInAction(() => {
      store.pageNumber = 1;
      // If we're filtering, we only want to replace the items being displayed
      if (!isFiltering) {
        store.items = products;
      }
      store.filteredItems = products;
      store.isLoading = false;
    });
  }

  @action
  resetItems(store: CatalogPageStore) {
    store.items = [];
  }

  @action
  setSearch(store: CatalogPageStore, search: string) {
    store.searchFilter = search;
  }

  @action
  setCategory(store: CatalogPageStore, category: string) {
    store.categoryFilter = category;
  }

  @action
  setSortBy(store: CatalogPageStore, sortBy: SortBy) {
    store.sortBy = sortBy;
  }

  @action
  setNumItemsDisplay(store: CatalogPageStore, numItems: number) {
    store.numItemsDisplay = numItems;
  }

  @action
  setPageNumber(store: CatalogPageStore, pageNumber: number) {
    store.pageNumber = pageNumber;
  }
}
