import { createContext, useContext } from "react";
import { User } from "services/auth/types";

export type AppState = {
  user: User | null;
};

export const AppContext = createContext<AppState>({
  user: null,
});

export const useAppContext = () => useContext(AppContext);
