import { Container } from "@mui/material";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppContext } from "routes/app_context";
import { Role } from "services/auth/types";
import React from "react";
import { useErrorHandler } from "react-error-boundary";

const PROTECTED_ROUTES = ["/signup", "/build"];

export const PageComponent = ({
  Header,
  fetchCart,
}: {
  Header: React.ComponentType;
  fetchCart: () => Promise<void>;
}) => {
  const location = useLocation();
  const handleError = useErrorHandler();
  const { user } = useAppContext();

  React.useEffect(() => {
    // Tries to initialise the order store with cart stored in the BE
    fetchCart().catch((e) => handleError(e));
  }, [fetchCart, handleError]);

  if (!user) {
    return <Navigate to="/login" replace={true} state={{ from: location }} />;
  } else if (
    PROTECTED_ROUTES.some((route) => route === location.pathname) &&
    user.role !== Role.Admin
  ) {
    // Protected routes should only be accessible by Admins
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header />
      <Container
        disableGutters={true}
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "32px",
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};
