import { AuthPage } from "pages/auth/page/auth_page";
import {
  AuthPagePresenter,
  AuthPageStore,
} from "pages/auth/page/auth_page_store";
import React from "react";
import { Role } from "services/auth/types";

export type AuthPageProps =
  | {
      type: "login";
      login: (username: string, password: string) => Promise<void>;
    }
  | {
      type: "signup";
      signup: (
        fullname: string,
        username: string,
        password: string,
        role: Role
      ) => Promise<void>;
    };

export const installAuthPage = (): {
  AuthPage: React.ComponentType<AuthPageProps>;
} => {
  const store = new AuthPageStore();

  const AuthPageImpl = (props: AuthPageProps) => {
    const presenter = new AuthPagePresenter(props);
    return <AuthPage type={props.type} store={store} presenter={presenter} />;
  };

  return { AuthPage: AuthPageImpl };
};
