import React from "react";
import styles from "./carousel.module.css";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export const Carousel = ({
  children,
}: {
  children?: JSX.Element | JSX.Element[];
}) => {
  const [currSlide, setCurrSlide] = React.useState<number>(0);
  const slides = Array.isArray(children)
    ? children.map((child, i) => (
        <div className={styles.item} key={i}>
          {child}
        </div>
      ))
    : [children];

  return (
    <div className={styles.container}>
      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>{slides[currSlide]}</div>
        {currSlide !== 0 && (
          <div className={styles.leftButtonContainer}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setCurrSlide(currSlide - 1)}
            >
              <ChevronLeft />
            </IconButton>
          </div>
        )}
        {currSlide !== slides.length - 1 && (
          <div className={styles.rightButtonContainer}>
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setCurrSlide(currSlide + 1)}
            >
              <ChevronRight />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        {Array.from({ length: slides.length }, (_, i) => (
          <IconButton key={i} onClick={() => setCurrSlide(i)}>
            {i === currSlide ? (
              <CircleIcon sx={{ fontSize: 12 }} />
            ) : (
              <CircleOutlinedIcon sx={{ fontSize: 12 }} />
            )}
          </IconButton>
        ))}
      </div>
    </div>
  );
};
