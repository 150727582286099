import { BuildPageType } from "pages/build/page/types";

export const Messages = {
  heading: (page: BuildPageType) =>
    `Build ${page[0].toUpperCase()}${page.slice(1)} List`,

  successMessage: (page: BuildPageType) => `Successfully built ${page} list.`,

  errorMessage: (page: BuildPageType) =>
    `Error building ${page} list - please double check source file and try again.`,

  loadingLabel: () => "Rebuilding...",

  productDescription: () =>
    "This will refresh the contents of the digital catalog with the contents of the .mdb file stored ",

  customerDescription: () =>
    "This will refresh the contents of the list of customers that can be selected from the contents of the .xslx file stored ",

  documentationInfo: (link: string) => (
    <>
      If there are any errors or unexpected changes when updating content,
      please refer to this{" "}
      <a href={link} target="_blank" rel="noreferrer">
        handover document
      </a>{" "}
      before reaching out to the developers.
    </>
  ),
};
