import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";
import { OrderStore } from "services/order/order_store";
import { OrderPresenter } from "services/order/order_presenter";
import { observer } from "mobx-react-lite";
import { CartPage } from "pages/cart/page/cart_page";
import { ProductPageProps } from "pages/product/page/install";
import { OrderItem } from "services/order/types";
import { ThemeProvider } from "@mui/material";
import { theme } from "ui/theme/theme";
import { HttpOrderService } from "services/order/http_order_service";
import { HttpClient } from "services/http/http_client";
import { HttpAuthService } from "services/auth/http_auth_service";
import { AuthStore } from "services/auth/auth_store";
import { AuthPresenter } from "services/auth/auth_presenter";
import { AuthPageProps } from "pages/auth/page/install";
import { ErrorBoundary } from "routes/error_boundary";
import { Role } from "services/auth/types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BuildPageProps } from "pages/build/page/install";
import { HttpCustomerService } from "services/customer/http_customer_service";
import { OrdersPageProps } from "pages/orders/page/install";
import { CatalogPageProps } from "pages/catalog/page/install";
import { Item } from "services/product/types";
import { Header } from "ui/nav/header/header";
import { AppContext } from "routes/app_context";
import { PageComponent } from "routes/page_component";
import { HttpCartService } from "services/cart/http_cart_service";

export const installRoutes = ({
  AuthPage,
  BuildPage,
  CatalogPage,
  ProductPage,
  OrdersPage,
}: {
  AuthPage: React.ComponentType<AuthPageProps>;
  BuildPage: React.ComponentType<BuildPageProps>;
  CatalogPage: React.ComponentType<CatalogPageProps>;
  ProductPage: React.ComponentType<ProductPageProps>;
  OrdersPage: React.ComponentType<OrdersPageProps>;
}) => {
  const customerService = new HttpCustomerService(new HttpClient());
  const orderService = new HttpOrderService(new HttpClient());
  const cartService = new HttpCartService(new HttpClient());
  const authService = new HttpAuthService(new HttpClient());
  // Order state needs to be shared across multiple pages of the app
  const authStore = new AuthStore();
  const authPresenter = new AuthPresenter(authService);
  const orderStore = new OrderStore();
  const orderPresenter = new OrderPresenter(
    orderService,
    customerService,
    cartService
  );

  // Try restore user and order from localStorage if available
  authPresenter.maybeRestoreUser(authStore);

  const logout = async () => {
    await authPresenter.logout(authStore);
  };

  const login = async (username: string, password: string) =>
    await authPresenter.login(authStore, username, password);

  const signup = async (
    fullname: string,
    username: string,
    password: string,
    role: Role
  ) =>
    await authPresenter.signup(authStore, fullname, username, password, role);

  const HeaderImpl = observer(() => (
    <Header numCartItems={orderStore.numItems} logout={logout} />
  ));

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <PageComponent
          Header={HeaderImpl}
          fetchCart={() =>
            orderPresenter.getCart(orderStore, authStore.user?.token ?? "")
          }
        />
      ),
      errorElement: <ErrorBoundary logout={logout} />,
      children: [
        {
          index: true,
          element: (
            <CatalogPage
              getNumItemsInCart={(itemId: string) =>
                orderPresenter.getOrderItem(orderStore, itemId)?.cartonQty ?? 0
              }
              updateItemInCart={(item: Item, quantity: number) => {
                orderPresenter.updateQuantity(
                  orderStore,
                  item,
                  quantity,
                  authStore.user?.token ?? ""
                );
              }}
            />
          ),
        },
        {
          path: ":itemId",
          element: (
            <ProductPage
              findItemInCart={(itemId: string) =>
                orderPresenter.getOrderItem(orderStore, itemId)
              }
              addToCart={(orderItem: OrderItem) => {
                orderPresenter.addItem(
                  orderStore,
                  orderItem,
                  authStore.user?.token ?? ""
                );
              }}
            />
          ),
        },
        {
          path: "cart",
          element: (
            <CartPage orderStore={orderStore} orderPresenter={orderPresenter} />
          ),
        },
        {
          path: "orders",
          element: (
            <OrdersPage
              orderStore={orderStore}
              fetchCustomers={async () => {
                await orderPresenter.getCustomers(
                  orderStore,
                  authStore?.user?.token ?? ""
                );
              }}
            />
          ),
        },
        {
          path: "build/product",
          element: <BuildPage page="product" />,
        },
        {
          path: "build/customer",
          element: <BuildPage page="customer" />,
        },
        {
          path: "signup",
          element: <AuthPage type="signup" signup={signup} />,
        },
      ],
    },
    {
      path: "/login",
      element: <AuthPage type="login" login={login} />,
    },
  ]);

  const RoutesImpl = observer(() => {
    const appState = {
      user: authStore.user,
    };

    return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppContext.Provider value={appState}>
            <RouterProvider router={router} />
          </AppContext.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    );
  });

  return RoutesImpl;
};
