import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { indigo, pink } from "@mui/material/colors";

export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: "DM Sans, Arial, sans-serif",
      body1: {
        color: "#000000",
      },
      subtitle1: {
        color: "#8c8c8c",
      },
      subtitle2: {
        color: "#8c8c8c",
      },
    },
    palette: {
      primary: indigo,
      secondary: pink,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: 12,
            fontWeight: 700,
            textTransform: "capitalize",
          },
        },
      },
    },
  })
);
