import { computed, makeObservable, observable } from "mobx";
import { OrderItem } from "services/order/types";
import { calculateOrderItemTotal } from "services/order/utils";

export class OrderFormStore {
  @observable.deep
  orderItem: OrderItem;

  @computed
  get subtotal() {
    const { item, cartonQty, discountRp } = this.orderItem;
    return (
      ((item.unitPrice ?? 0) - (discountRp ?? 0)) * item.packing * cartonQty
    );
  }

  @computed
  get orderTotal() {
    return calculateOrderItemTotal(this.orderItem);
  }

  constructor(orderItem: OrderItem) {
    makeObservable(this);
    this.orderItem = orderItem;
  }
}
