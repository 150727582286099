import { UnreachableError } from "base/preconditions";
import { makeObservable, observable, action } from "mobx";
import { AuthPageProps } from "pages/auth/page/install";
import { Role } from "services/auth/types";

export class AuthPageStore {
  @observable.ref
  fullname: string = "";

  @observable.ref
  username: string = "";

  @observable.ref
  password: string = "";

  @observable.ref
  role: Role = Role.User;

  constructor() {
    makeObservable(this);
  }
}

export class AuthPagePresenter {
  @action
  setUsername(store: AuthPageStore, username: string) {
    store.username = username;
  }

  @action
  setPassword(store: AuthPageStore, password: string) {
    store.password = password;
  }

  @action
  setFullname(store: AuthPageStore, fullname: string) {
    store.fullname = fullname;
  }

  @action
  setRole(store: AuthPageStore, role: Role) {
    store.role = role;
  }

  action: (store: AuthPageStore) => Promise<void>;

  constructor(props: AuthPageProps) {
    makeObservable(this);

    switch (props.type) {
      case "login":
        this.action = async (store: AuthPageStore) =>
          await props.login(store.username, store.password);
        break;
      case "signup":
        this.action = async (store: AuthPageStore) =>
          await props.signup(
            store.fullname,
            store.username,
            store.password,
            store.role
          );
        break;
      default:
        throw new UnreachableError(props);
    }
  }
}
