import { ErrorPage } from "pages/error/page/error_page";
import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { ExpiredSessionError } from "services/errors/expired_session_error";

export const ErrorBoundary = ({ logout }: { logout: () => Promise<void> }) => {
  const error = useRouteError();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (error instanceof ExpiredSessionError) {
      logout().then(() => {
        navigate("/login?sessionExpired");
      });
    }
  }, [error, logout, navigate]);

  return <ErrorPage />;
};
