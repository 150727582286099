import dayjs from "dayjs";
import { computed, makeObservable, observable } from "mobx";
import { Cart, CartItems, Order } from "services/order/types";
import {
  calculateOrderSubtotal,
  calculateOrderTotal,
} from "services/order/utils";

export class OrderStore {
  /**
   * All other information about the order excluding the list of
   * items. This is maintained in orderItemsMap instead.
   */
  @observable.deep
  orderInfo: Omit<Order, "orderItems"> = {
    customer: "",
    createdBy: "",
    createdAt: dayjs().format("DD/MM/YYYY"),
    discountPercent: 0,
    discountRp: 0,
  };

  /**
   * This map provides easier update operations for maintaining
   * the cart in the store and is the source of truth for
   * orderItems in the final order submitted to the BE
   */
  @observable.deep
  orderItemsMap: CartItems = {};

  /**
   * List of customers that this order can be issued for.
   */
  @observable.ref
  customerList: string[] = [];

  @computed
  get order(): Order {
    return {
      ...this.orderInfo,
      orderItems: Object.values(this.orderItemsMap),
    };
  }

  @computed
  get numItems(): number {
    return Object.values(this.orderItemsMap).length;
  }

  @computed
  get numCartons(): number {
    return Object.values(this.orderItemsMap)
      .map((orderItem) => orderItem.cartonQty)
      .reduce((a, b) => a + b, 0);
  }

  @computed
  get subtotal() {
    return calculateOrderSubtotal(this.order);
  }

  @computed
  get total() {
    return calculateOrderTotal(this.order);
  }

  constructor(cart?: Cart) {
    makeObservable(this);

    if (cart) {
      this.orderInfo = {
        createdBy: "",
        createdAt: dayjs().format("DD/MM/YYYY"),
        ...cart,
      };

      this.orderItemsMap = Object.fromEntries(
        cart.orderItems.map((orderItem) => [orderItem.item.itemId, orderItem])
      );
    }
  }
}
