export const enum Role {
  User = "USER",
  Admin = "ADMIN",
  Guest = "GUEST",
  AdminViewOldEmpty = "ADMIN_VIEW_OLD_EMPTY",
}

export type User = {
  username: string;
  fullname: string;
  token: string;
  role: Role;
};
