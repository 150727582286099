import { makeObservable, observable } from "mobx";
import { User } from "services/auth/types";

export class AuthStore {
  constructor() {
    makeObservable(this);
  }

  @observable.deep
  user: User | null = null;
}
