import { CircularProgress } from "@mui/material";
import { Container } from "@mui/system";

export const LoadingPage = () => (
  <Container
    sx={{
      height: "50vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Container>
);
