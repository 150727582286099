import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export type Notification = {
  message: string;
  type: "error" | "info" | "success" | "warning";
};

export const Toast = ({
  notification,
  open,
  onClose,
}: {
  notification: Notification;
  open: boolean;
  onClose?: () => void;
}) => {
  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={onClose}>
      <Alert
        severity={notification.type}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
