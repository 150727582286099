import {
  AppBar,
  Avatar,
  ListItemIcon,
  Menu,
  MenuItem,
  IconButton,
  Toolbar,
  Badge,
  Typography,
} from "@mui/material";
import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InventoryIcon from "@mui/icons-material/Inventory";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import ViewListIcon from "@mui/icons-material/ViewList";
import styles from "./header.module.css";
import { UnstyledLink } from "ui/base/link/unstyled_link";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { stringToColor, getInitials } from "ui/nav/header/util";
import { Role } from "services/auth/types";
import { Logo } from "ui/base/logo/logo";
import { useAppContext } from "routes/app_context";

export type HeaderProps = {
  numCartItems: number;
  logout: () => Promise<void>;
};

export const Header = ({ numCartItems, logout }: HeaderProps) => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <div className={styles.container}>
          <UnstyledLink to="/">
            <div className={styles.logo}>
              <Logo size="small" />
              <Typography
                variant="h1"
                style={{
                  fontWeight: 700,
                  fontSize: "1.4rem",
                }}
                className={styles.logoText}
              >
                Salim Toys Company
              </Typography>
            </div>
          </UnstyledLink>
          {user && (
            <div className={styles.buttons}>
              {user.role !== Role.Guest && (
                <UnstyledLink to="/cart">
                  <IconButton size="large">
                    <Badge badgeContent={numCartItems} color="secondary">
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </UnstyledLink>
              )}
              <IconButton onClick={handleClick}>
                <Avatar
                  sx={{
                    bgcolor: user?.fullname
                      ? stringToColor(user.fullname)
                      : undefined,
                  }}
                >
                  {user?.fullname && getInitials(user.fullname)}
                </Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {user &&
                  user.role === Role.Admin && [
                    <MenuItem
                      onClick={() => navigate("/build/customer")}
                      key="customers"
                    >
                      <ListItemIcon>
                        <PeopleIcon fontSize="small" />
                      </ListItemIcon>
                      Reload Customers
                    </MenuItem>,
                    <MenuItem
                      onClick={() => navigate("/build/product")}
                      key="products"
                    >
                      <ListItemIcon>
                        <InventoryIcon fontSize="small" />
                      </ListItemIcon>
                      Reload Products
                    </MenuItem>,
                    <MenuItem
                      onClick={() => navigate("/signup")}
                      key="newUsers"
                    >
                      <ListItemIcon>
                        <PersonAddIcon fontSize="small" />
                      </ListItemIcon>
                      Create New Users
                    </MenuItem>,
                  ]}
                {user && user.role !== Role.Guest && (
                  <MenuItem onClick={() => navigate("/orders")}>
                    <ListItemIcon>
                      <ViewListIcon fontSize="small" />
                    </ListItemIcon>
                    Orders
                  </MenuItem>
                )}
                <MenuItem
                  onClick={async () => {
                    await logout();
                    navigate("/login");
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
