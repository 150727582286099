import { Order } from "services/order/types";

export class InvoiceGenerator {
  static async download(order: Order) {
    const { customer, createdAt } = order;

    // Lazy load since React PDF has a massive bundle size
    const { pdf } = await import("@react-pdf/renderer");
    const { Receipt } = await import("./receipt/receipt");

    const invoiceBlob = await pdf(<Receipt order={order} />).toBlob();
    const blobUrl = URL.createObjectURL(invoiceBlob);

    const downloadEl = document.createElement("a");
    downloadEl.href = blobUrl;
    downloadEl.download = `${customer}-${createdAt}.pdf`;
    downloadEl.click();

    URL.revokeObjectURL(blobUrl);
  }
}
