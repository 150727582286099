import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { makeObservable, action, runInAction } from "mobx";
import { OrdersPageStore } from "pages/orders/page/orders_page_store";
import { OrderGenerator } from "services/order/order_generator";
import { OrderService } from "services/order/order_service";

// Install UTC plugin for Dayjs
dayjs.extend(utc);

export class OrdersPagePresenter {
  constructor(private readonly orderService: OrderService) {
    makeObservable(this);
  }

  @action
  async getOrders(store: OrdersPageStore, token: string) {
    store.loading = true;
    const { orders } = await this.orderService.getOrders({
      createdBy: store.createdBy,
      customer: store.customer,
      fromDate: store.fromDate ? store.fromDate.startOf('day').utc().toISOString() : undefined,
      toDate: store.toDate ? store.toDate.endOf('day').utc().toISOString() : undefined,
      token,
    });
    runInAction(() => {
      store.orders = orders;
      store.loading = false;
    });
  }

  @action
  async downloadOrderSummary(store: OrdersPageStore) {
    store.downloadLoading = true;
    OrderGenerator.downloadCsv(store.orders);
    store.downloadLoading = false;
    store.confirmModalOpen = false;
  }

  @action
  setCreatedBy(store: OrdersPageStore, createdBy: string) {
    store.createdBy = createdBy;
  }

  @action
  setCustomer(store: OrdersPageStore, customer: string) {
    store.customer = customer;
  }

  @action
  setFromDate(store: OrdersPageStore, date: Dayjs | null) {
    store.fromDate = date;
  }

  @action
  setToDate(store: OrdersPageStore, date: Dayjs | null) {
    store.toDate = date;
  }

  @action
  setConfirmModalOpen(store: OrdersPageStore, open: boolean) {
    store.confirmModalOpen = open;
  }
}
