import { action, makeObservable, runInAction } from "mobx";
import { AuthService } from "services/auth/auth_service";
import { AuthStore } from "services/auth/auth_store";
import { Role, User } from "services/auth/types";

const USER_LOCAL_STORAGE_KEY = "USER";

export class AuthPresenter {
  constructor(private readonly authService: AuthService) {
    makeObservable(this);
  }

  /**
   * We store the user's information with a TTL in localStorage. Try restore the user info
   * from there if it's available during the install phase of the app.
   */
  @action
  maybeRestoreUser(store: AuthStore) {
    const user = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
    store.user = user ? (JSON.parse(user) as User) : null;
  }

  @action
  async login(store: AuthStore, username: string, password: string) {
    const user = await this.authService.login({ username, password });
    if (user) {
      runInAction(() => {
        store.user = user;
      });
      // Store user data in localStorage with a TTL so it can be restored
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
    }
  }

  @action
  async logout(store: AuthStore) {
    runInAction(() => {
      store.user = null;
    });
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
  }

  @action
  async signup(
    store: AuthStore,
    fullname: string,
    username: string,
    password: string,
    role: Role
  ) {
    const user = await this.authService.signup({
      fullname,
      username,
      password,
      role,
    });
    runInAction(() => {
      store.user = user;
    });
    // Store user data in localStorage with a TTL so it can be restored
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
  }
}
