import dayjs from "dayjs";
import { action, makeObservable } from "mobx";
import { CartService } from "services/cart/cart_service";
import { CustomerService } from "services/customer/customer_service";
import { OrderService } from "services/order/order_service";
import { OrderStore } from "services/order/order_store";
import { Cart, OrderItem } from "services/order/types";
import { Item } from "services/product/types";

export class OrderPresenter {
  constructor(
    private readonly orderService: OrderService,
    private readonly customerService: CustomerService,
    private readonly cartService: CartService
  ) {
    makeObservable(this);
  }

  @action
  async getCart(store: OrderStore, token: string) {
    const userCart = await this.cartService.getCart({ token });
    this.updateStoreWithCart(store, userCart);
  }

  @action
  async sendCartUpdate(store: OrderStore, token: string) {
    // Since Cart is a subset of the Order type, we can send the whole order object
    // and let the BE extract the fields that it needs
    return await this.cartService.updateCart({
      token,
      cart: store.order,
    });
  }

  @action
  private updateStoreWithCart(store: OrderStore, cart: Cart) {
    store.orderInfo = {
      ...store.orderInfo,
      ...cart,
    };
    store.orderItemsMap = Object.fromEntries(
      cart.orderItems.map((orderItem) => [orderItem.item.itemId, orderItem])
    );
  }

  @action
  async getCustomers(store: OrderStore, token: string) {
    const { customers } = await this.customerService.getCustomers({ token });
    store.customerList = customers;
  }

  @action
  setCustomer(store: OrderStore, name: string, token: string) {
    store.orderInfo.customer = name;
    this.sendCartUpdate(store, token);
  }

  @action
  setDiscountRp(store: OrderStore, val: number) {
    store.orderInfo.discountRp = val;
  }

  @action
  setDiscountPercent(store: OrderStore, val: number) {
    store.orderInfo.discountPercent = val;
  }

  @action
  setNotes(store: OrderStore, notes: string) {
    store.orderInfo.notes = notes;
  }

  @action
  removeItem(store: OrderStore, itemId: string, token: string) {
    delete store.orderItemsMap[itemId];
    this.sendCartUpdate(store, token);
  }

  @action
  addItem(store: OrderStore, orderItem: OrderItem, token: string) {
    const itemId = orderItem.item.itemId;
    store.orderItemsMap[itemId] = orderItem;
    this.sendCartUpdate(store, token);
  }

  /**
   * Used when looking to quick add/remove an item via the number input on
   * catalog pages and cart pages
   */
  @action
  updateQuantity(
    store: OrderStore,
    item: Item,
    quantity: number,
    token: string
  ) {
    const { itemId } = item;
    if (quantity === 0) {
      this.removeItem(store, itemId, token);
      return;
    }

    const orderItem = this.getOrderItem(store, itemId);
    if (!orderItem) {
      const newOrderItem: OrderItem = {
        item,
        cartonQty: quantity,
        unitQty: 0,
        discountPercent: 0,
        discountRp: 0,
      };
      this.addItem(store, newOrderItem, token);
    } else {
      // Already in cart so we just need to update the quantity
      store.orderItemsMap[itemId].cartonQty = quantity;
      this.sendCartUpdate(store, token);
    }
  }

  @action
  clearItems(store: OrderStore, token: string) {
    store.orderItemsMap = {};
    this.sendCartUpdate(store, token);
  }

  @action
  clearOrder(store: OrderStore, token: string) {
    store.orderInfo = {
      customer: "",
      createdBy: "",
      createdAt: dayjs().format("DD/MM/YYYY"),
      discountPercent: 0,
      discountRp: 0,
    };
    store.orderItemsMap = {};
    this.sendCartUpdate(store, token);
  }

  getOrderItem(store: OrderStore, itemId: string): OrderItem | undefined {
    return store.orderItemsMap[itemId];
  }

  @action
  async confirmOrder(store: OrderStore, fullname: string, token: string) {
    store.orderInfo.createdBy = fullname;
    // Update order create timestamp when order confirmed
    store.orderInfo.createdAt = dayjs().format("DD/MM/YYYY");
    // Send order object to BE to store in database
    this.orderService.createOrder({ order: store.order, token });
  }
}
