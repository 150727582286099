import { Alert, Typography } from "@mui/material";
import { Messages } from "pages/build/page/build_page.messages";
import { BuildPageType } from "pages/build/page/types";
import { LoadingButton } from "ui/base/loading_button/loading_button";
import { Toast, Notification } from "ui/base/toast/toast";
import styles from "./build_page.module.css";
import { useAppContext } from "routes/app_context";

const S3_MDB_LINK =
  "https://s3.console.aws.amazon.com/s3/buckets/stc-repo-prod?region=ap-southeast-1&tab=objects";

const S3_CUSTOMER_LINK =
  "https://s3.console.aws.amazon.com/s3/buckets/stc-customer-prod?region=ap-southeast-1&tab=objects";

const HANDOVER_DOC_LINK =
  "https://docs.google.com/document/d/1E8sG7rMxa4NSm4FmJDCLPfulqD9Jg_A_8kL5lhOxnVk/edit#";

export const BuildPage = ({
  page,
  onBuild,
  loading,
  notification,
  closeNotification,
}: {
  page: BuildPageType;
  onBuild?: (token: string) => void;
  loading?: boolean;
  notification?: Notification;
  closeNotification?: () => void;
}) => {
  const { user } = useAppContext();

  return (
    <>
      <Alert severity="info">
        {Messages.documentationInfo(HANDOVER_DOC_LINK)}
      </Alert>
      <div className={styles.page}>
        <Typography variant="h4" align="center" sx={{ fontWeight: 700 }}>
          {Messages.heading(page)}
        </Typography>
        <Typography variant="body1" align="center">
          {page === "customer"
            ? Messages.customerDescription()
            : Messages.productDescription()}
          <a
            href={page === "customer" ? S3_CUSTOMER_LINK : S3_MDB_LINK}
            target="_blank"
            rel="noreferrer"
            style={{ fontWeight: 700 }}
          >
            here
          </a>
          .
        </Typography>
        <LoadingButton
          loadingLabel={Messages.loadingLabel()}
          loading={loading}
          onClick={() => onBuild?.(user?.token ?? "")}
        >
          {Messages.heading(page)}
        </LoadingButton>
        {notification && (
          <Toast
            notification={notification}
            open={notification !== undefined}
            onClose={closeNotification}
          />
        )}
      </div>
    </>
  );
};
