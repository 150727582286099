import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Table,
  TableBody,
  Button,
  Box,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { OrderFormPresenter } from "pages/product/order_form/order_form_presenter";
import { OrderFormStore } from "pages/product/order_form/order_form_store";
import { OrderItem } from "services/order/types";
import { NumberInput } from "ui/base/number_input/number_input";
import { formatRupiah } from "ui/utils/currency";
import styles from "./order_form.module.css";

export const OrderForm = observer(
  ({
    orderFormStore,
    orderFormPresenter,
    addToCart,
  }: {
    orderFormStore: OrderFormStore;
    orderFormPresenter: OrderFormPresenter;
    addToCart?: (orderItem: OrderItem) => void;
  }) => {
    const { item, cartonQty, discountPercent, discountRp, notes } =
      orderFormStore.orderItem;

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Packing</TableCell>
                {item.unitPrice && <TableCell align="center">Harga</TableCell>}
                <TableCell align="center">Qty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    CTN
                  </Typography>
                  <Typography variant="body1">
                    ({item.packing} {item.unit} / CTN)
                  </Typography>
                </TableCell>
                {item.unitPrice && (
                  <TableCell align="center" sx={{ fontWeight: 700 }}>
                    {formatRupiah(item.unitPrice)} / {item.unit}
                  </TableCell>
                )}
                <TableCell align="center">
                  <div className={styles.cellCenter}>
                    <NumberInput
                      size="small"
                      value={cartonQty}
                      onChange={(val) =>
                        orderFormPresenter.setCartonQty(orderFormStore, val)
                      }
                    />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} />
                <TableCell>Pot. Harga (Rp) / {item.unit}</TableCell>
                <TableCell align="center">
                  <div className={styles.cellCenter}>
                    <NumberInput
                      value={discountRp}
                      onChange={(val) =>
                        orderFormPresenter.setDiscountRp(orderFormStore, val)
                      }
                      step={1000}
                      maxValue={item.unitPrice}
                      disabled={orderFormStore.subtotal === 0}
                    />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Subtotal</TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  {formatRupiah(orderFormStore.subtotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Diskon (%)</TableCell>
                <TableCell align="center">
                  <div className={styles.cellCenter}>
                    <NumberInput
                      size="small"
                      value={discountPercent}
                      maxValue={100}
                      onChange={(val) =>
                        orderFormPresenter.setDiscountPercent(
                          orderFormStore,
                          val
                        )
                      }
                      disabled={orderFormStore.subtotal === 0}
                    />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Total</TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  {formatRupiah(orderFormStore.orderTotal)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <TextField
            placeholder="Notes"
            rows={2}
            multiline={true}
            fullWidth={true}
            value={notes}
            onChange={(e) =>
              orderFormPresenter.setNotes(orderFormStore, e.target.value)
            }
          />
        </div>
        <Button
          variant="contained"
          disabled={orderFormStore.subtotal === 0}
          onClick={() => addToCart?.(orderFormStore.orderItem)}
        >
          Add to Cart
        </Button>
      </div>
    );
  }
);
