import { observer } from "mobx-react-lite";
import { CatalogPage } from "pages/catalog/page/catalog_page";
import React from "react";
import { CatalogPageStore } from "pages/catalog/page/catalog_page_store";
import { CatalogPagePresenter } from "pages/catalog/page/catalog_page_presenter";
import { HttpClient } from "services/http/http_client";
import { HttpProductService } from "services/product/http_product_service";
import { Item } from "services/product/types";

export type CatalogPageProps = {
  getNumItemsInCart: (itemId: string) => number;
  updateItemInCart: (item: Item, quantity: number) => void;
};

export const installCatalogPage = (): {
  CatalogPage: React.ComponentType<CatalogPageProps>;
  clearCachedItemList: () => void;
} => {
  const productService = new HttpProductService(new HttpClient());
  const store = new CatalogPageStore();
  const presenter = new CatalogPagePresenter(productService);

  const CatalogPageImpl = observer(
    ({ getNumItemsInCart, updateItemInCart }: CatalogPageProps) => (
      <CatalogPage
        store={store}
        presenter={presenter}
        getNumItemsInCart={getNumItemsInCart}
        updateItemInCart={updateItemInCart}
      />
    )
  );

  return {
    CatalogPage: CatalogPageImpl,
    clearCachedItemList: () => presenter.resetItems(store),
  };
};
