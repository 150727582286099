import { makeObservable, observable } from "mobx";
import { Notification } from "ui/base/toast/toast";

export class BuildPageStore {
  @observable.ref
  loading?: boolean;

  @observable.deep
  notification?: Notification;

  @observable.ref
  error?: string;

  constructor() {
    makeObservable(this);
  }
}
