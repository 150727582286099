import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment,
  Typography,
  Pagination,
  Chip,
} from "@mui/material";
import { ItemCard } from "pages/catalog/item_card/item_card";
import React, { useCallback, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./catalog_page.module.css";
import { UnstyledLink } from "ui/base/link/unstyled_link";
import { FilledInput } from "@mui/material";
import { IconButton } from "@mui/material";
import { Item, SortBy } from "services/product/types";
import { CatalogPageStore } from "pages/catalog/page/catalog_page_store";
import { CatalogPagePresenter } from "pages/catalog/page/catalog_page_presenter";
import { observer } from "mobx-react-lite";
import { LoadingPage } from "pages/loading/loading_page";
import { NumberInput } from "ui/base/number_input/number_input";
import { useAppContext } from "routes/app_context";
import { useErrorHandler } from "react-error-boundary";

export const CatalogPage = observer(
  ({
    store,
    presenter,
    getNumItemsInCart,
    updateItemInCart,
  }: {
    store: CatalogPageStore;
    presenter: CatalogPagePresenter;
    getNumItemsInCart: (itemId: string) => number;
    updateItemInCart: (item: Item, quantity: number) => void;
  }) => {
    const { user } = useAppContext();
    const handleError = useErrorHandler();

    const fetchItems = useCallback(
      (isFiltering?: boolean) => {
        presenter
          .getItems(store, user?.token ?? "", isFiltering)
          .catch((e) => handleError(e));
      },
      [presenter, store, handleError, user?.token]
    );

    useEffect(() => {
      fetchItems();
    }, [fetchItems]);

    return (
      <>
        <Typography variant="h3" style={{ fontWeight: 700 }}>
          Our Products
        </Typography>

        {/* Filter Row */}
        <div className={styles.filters}>
          <FormControl
            className={styles.search}
            variant="filled"
            fullWidth={true}
          >
            <InputLabel>Search by Product Title or Item Code</InputLabel>
            <FilledInput
              value={store.searchFilter}
              onKeyDown={(e) => {
                e.key === "Enter" && fetchItems(true);
              }}
              onChange={(e) => presenter.setSearch(store, e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => fetchItems(true)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div className={styles.selectFilter}>
            <FormControl fullWidth={true}>
              <InputLabel>Catalog</InputLabel>
              <Select
                label="Catalog"
                value={store.categoryFilter}
                onChange={(e) => {
                  presenter.setCategory(store, e.target.value);
                  fetchItems(true);
                }}
              >
                {store.categories.map(({ value, label }, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles.selectFilter}>
            <FormControl fullWidth={true}>
              <InputLabel>Sort by</InputLabel>
              <Select
                label="Sort by"
                value={store.sortBy}
                onChange={(e) => {
                  let sortBy: SortBy;
                  switch (e.target.value) {
                    case SortBy.NONE:
                      sortBy = SortBy.NONE;
                      break;
                    case SortBy.NAME_ASC:
                      sortBy = SortBy.NAME_ASC;
                      break;
                    case SortBy.NAME_DESC:
                      sortBy = SortBy.NAME_DESC;
                      break;
                    case SortBy.PRICE_ASC:
                      sortBy = SortBy.PRICE_ASC;
                      break;
                    case SortBy.PRICE_DESC:
                      sortBy = SortBy.PRICE_DESC;
                      break;
                    default:
                      throw new Error("Invalid Sort By Value");
                  }
                  presenter.setSortBy(store, sortBy);
                  fetchItems(true);
                }}
              >
                {store.sortOptions.map(({ value, label }, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {store.isLoading ? (
          <LoadingPage />
        ) : (
          <>
            {/* Products per page filter */}
            <div className={styles.productsPerPage}>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Showing {store.startIndex} - {store.endIndex} of{" "}
                {store.filteredItems.length} products
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <Select
                  label="Items"
                  variant="standard"
                  value={store.numItemsDisplay}
                  autoWidth={true}
                  onChange={(e) => {
                    presenter.setNumItemsDisplay(store, Number(e.target.value));
                    presenter.setPageNumber(store, 1);
                  }}
                >
                  <MenuItem value={33}>33</MenuItem>
                  <MenuItem value={66}>66</MenuItem>
                  <MenuItem value={99}>99</MenuItem>
                </Select>
                <Typography variant="body2">products per page</Typography>
              </div>
            </div>

            {/* Item List */}
            <div className={styles.itemList}>
              {store.displayItems.map((item, i) => (
                <div className={styles.item} key={i}>
                  <UnstyledLink to={item.itemId}>
                    <ItemCard item={item} />
                  </UnstyledLink>
                  <div className={styles.qtyInput}>
                    <NumberInput
                      size="small"
                      value={getNumItemsInCart(item.itemId)}
                      onChange={(val) => updateItemInCart(item, val)}
                    />
                    {getNumItemsInCart(item.itemId) > 0 && (
                      <Chip
                        label="Added to Cart"
                        color="success"
                        size="small"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                count={store.pages}
                page={store.pageNumber}
                onChange={(e, value) => {
                  presenter.setPageNumber(store, value);
                  window.scrollTo(0, 0);
                }}
                color="primary"
                size="large"
              />
            </div>
          </>
        )}
      </>
    );
  }
);
