import { Order, OrderItem } from "services/order/types";

function applyDiscount(
  total: number,
  discountRp: number = 0,
  discountPercent: number = 0
) {
  return (total - discountRp) * (1 - discountPercent / 100);
}

export function calculateOrderItemTotal(orderItem: OrderItem) {
  const { item, cartonQty, discountPercent, discountRp } = orderItem;
  const { unitPrice, packing } = item;

  const qty = packing * cartonQty;
  const total = (unitPrice ?? 0) * qty;
  const netRpDiscount = (discountRp ?? 0) * qty;

  return applyDiscount(total, netRpDiscount, discountPercent);
}

export function calculateOrderSubtotal(order: Order) {
  return order.orderItems.reduce<number>((acc, orderItem) => {
    return acc + calculateOrderItemTotal(orderItem);
  }, 0);
}

export function calculateOrderTotal(order: Order) {
  const { discountRp, discountPercent } = order;
  const orderSubtotal = calculateOrderSubtotal(order);
  return applyDiscount(orderSubtotal, discountRp, discountPercent);
}
