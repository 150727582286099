import { Picture } from "ui/base/picture/picture";
import { Video } from "ui/base/video/video";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import React from "react";
import styles from "./media.module.css";
import { ErrorBoundary } from "react-error-boundary";

type MediaProps = {
  src: string;
  alt: string;
  type?: "image" | "video";
  alternativeUrls?: string[];
};

export const Media = (props: MediaProps) => {
  const MediaImpl = ({ src, alt, type, alternativeUrls = [] }: MediaProps) => {
    /**
     * HACK: For an image's imageURL, the BE does a hack where it uses the itemID to construct a
     * URL to the item's image file, attaching a lowercase file extension (i.e. .jpg). However,
     * the user may upload an image file with an extension that is uppercase (i.e. .JPG). The BE
     * supplies alterate image URLs for the FE to try.
     */
    const [toRetry, setToRetry] = React.useState<string[]>(alternativeUrls);
    const [error, setError] = React.useState<boolean>(false);
    const [mediaSrc, setMediaSrc] = React.useState<string>(src);

    if (error) {
      return <MediaFallback />;
    }

    const retryWithAlternativeSrc = () => {
      const newToRetry = [...toRetry];
      const srcToRetry = newToRetry.pop();
      setToRetry(newToRetry);
      if (srcToRetry) {
        setMediaSrc(srcToRetry);
      }
    };

    const onError = () => {
      if (toRetry.length) {
        retryWithAlternativeSrc();
        return;
      }
      setError(true);
      throw new Error(`Unable to load media: ${src}`);
    };

    if (type) {
      if (type === "image") {
        return <Picture alt={alt} imageUrl={mediaSrc} onError={onError} />;
      } else if (type === "video") {
        return <Video src={mediaSrc} onError={onError} />;
      }
    }

    const extension = src.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "jpeg":
      case "jpg":
      case "png":
        return <Picture alt={alt} imageUrl={mediaSrc} onError={onError} />;
      case "mp4":
        return <Video src={mediaSrc} onError={onError} />;
      default:
        setError(true);
        throw new Error("Unsupported media file type");
    }
  };

  return (
    <ErrorBoundary
      fallbackRender={() => {
        return <MediaFallback />;
      }}
    >
      <MediaImpl {...props} />
    </ErrorBoundary>
  );
};

export const MediaFallback = () => (
  <div className={styles.fallback}>
    <ImageNotSupportedIcon fontSize="large" />
  </div>
);
