import { OrderForm } from "pages/product/order_form/order_form";
import { OrderFormPresenter } from "pages/product/order_form/order_form_presenter";
import { OrderFormStore } from "pages/product/order_form/order_form_store";
import { OrderItem } from "services/order/types";

export type OrderFormProps = {
  orderItem: OrderItem;
  addToCart?: (orderItem: OrderItem) => void;
};

export const installOrderForm = (): {
  OrderForm: React.ComponentType<OrderFormProps>;
} => {
  const presenter = new OrderFormPresenter();

  const OrderFormImpl = ({ orderItem, addToCart }: OrderFormProps) => {
    const store = new OrderFormStore(orderItem);
    return (
      <OrderForm
        orderFormStore={store}
        orderFormPresenter={presenter}
        addToCart={addToCart}
      />
    );
  };

  return { OrderForm: OrderFormImpl };
};
