import { Order, OrderItem } from "services/order/types";
import {
  calculateOrderItemTotal,
  calculateOrderSubtotal,
  calculateOrderTotal,
} from "services/order/utils";
import { Item } from "services/product/types";
import { UnreachableError } from "base/preconditions";

type ItemKeyHeadings = {
  heading: string;
} & (
  | {
      type: "OrderItem";
      key: keyof OrderItem;
    }
  | {
      type: "Item";
      key: keyof Item;
    }
  | {
      type: "Calculated";
      key: "cartonPrice" | "subtotal";
    }
);

/**
 * This class is responsible for converting an order to a CSV file that can be downloaded
 * on the user's browser.
 */
export class OrderGenerator {
  static downloadCsv(orders: Order[]) {
    const content = orders
      .map((order) => OrderGenerator.generateOrderCsvContent(order))
      .join("\n");
    const blob = new Blob([content], { type: "text/csv" });
    // Creating an object for downloading url
    const url = URL.createObjectURL(blob);
    // Creating an anchor(a) tag of HTML to download the URL
    const a = document.createElement("a");
    a.href = url;
    a.download =
      orders.length === 1
        ? `Order ${orders[0].customer}.csv`
        : `Order Summary.csv`;
    a.click();
    URL.revokeObjectURL(url);
  }

  private static generateOrderCsvContent(order: Order) {
    const customerRow = ["Customer", order.customer];
    const createdByRow = ["Salesperson", order.createdBy];
    const dateRow = ["Tanggal", order.createdAt];
    const orderSubtotalRow = ["Subtotal", calculateOrderSubtotal(order)];
    const orderDiscountRow = ["Pot. Harga (Rp)", order.discountRp ?? "-"];
    const orderDiscountPercentRow = ["Diskon %", order.discountPercent ?? "-"];
    const orderTotalRow = ["Total", calculateOrderTotal(order)];
    const orderNotesRow = ["Notes", order.notes ?? "-"];
    const emptyCell = [""];
    const emptyRowPrefix = [emptyCell, emptyCell, emptyCell];

    const itemRowKeyHeadings: ItemKeyHeadings[] = [
      {
        type: "Item",
        key: "displayId",
        heading: "Kode Produk",
      },
      {
        type: "Item",
        key: "title",
        heading: "Nama Produk",
      },
      {
        type: "Item",
        key: "unit",
        heading: "Satuan",
      },
      {
        type: "Item",
        key: "packing",
        heading: "Packing",
      },
      {
        type: "Item",
        key: "unitPrice",
        heading: "Harga / Unit",
      },
      {
        type: "OrderItem",
        key: "discountRp",
        heading: "Pot. Harga (Rp)",
      },
      {
        type: "OrderItem",
        key: "cartonQty",
        heading: "Qty",
      },
      {
        type: "OrderItem",
        key: "discountPercent",
        heading: "Diskon %",
      },
      {
        type: "OrderItem",
        key: "notes",
        heading: "Notes",
      },
    ];

    const itemsHeadingRow = itemRowKeyHeadings.map(
      (itemKeyHeading) => itemKeyHeading.heading
    );

    const orderItemRows = order.orderItems.map((orderItem) =>
      itemRowKeyHeadings.map((itemKeyHeading) => {
        switch (itemKeyHeading.type) {
          case "Item":
            return orderItem.item[itemKeyHeading.key] ?? "-";
          case "OrderItem":
            return orderItem[itemKeyHeading.key] ?? "-";
          case "Calculated":
            switch (itemKeyHeading.key) {
              case "cartonPrice":
                return (orderItem.item.unitPrice ?? 0) * orderItem.item.packing;
              case "subtotal":
                return calculateOrderItemTotal(orderItem);
              default:
                throw new UnreachableError(itemKeyHeading);
            }
          default:
            throw new UnreachableError(itemKeyHeading);
        }
      })
    );

    /**
     * To make the CSV file easily processable, we want to specify all order details in
     * the first 2 columns and align the order items in columns 4 onwards.
     */
    const NUM_ORDER_DETAIL_ROWS = 9;
    const csvRows: any[][] = [
      [customerRow, emptyCell, itemsHeadingRow],
      [createdByRow, emptyCell],
      [dateRow, emptyCell],
      [...emptyRowPrefix],
      ["Detail Order", emptyCell, emptyCell],
      [orderSubtotalRow, emptyCell],
      [orderDiscountRow, emptyCell],
      [orderDiscountPercentRow, emptyCell],
      [orderTotalRow, emptyCell],
      [orderNotesRow, emptyCell],
    ];
    /**
     * Each order item needs to be prefixed by 3 cells. If no order details are available in
     * the first few cells, we need to pad the row with extra empty cells
     */
    orderItemRows.forEach((orderItemRow, i) => {
      if (i < NUM_ORDER_DETAIL_ROWS) {
        csvRows[i + 1].push(...orderItemRow);
      } else {
        csvRows.push([emptyRowPrefix, orderItemRow]);
      }
    });
    // Pad end of the CSV file with an empty row
    csvRows.push(emptyRowPrefix);

    const csvFileContent = csvRows
      .map((row) => row.flat().join(","))
      .join("\n");
    return csvFileContent;
  }
}
