import { RequestWithAuth } from "services/base";
import { CartService, UpdateCartReq } from "services/cart/cart_service";
import { HttpService } from "services/http/http_service";
import { Cart } from "services/order/types";

const BASE_PREFIX = "/cart";

export class HttpCartService implements CartService {
  constructor(private readonly httpService: HttpService) {}

  // Makes sure that the number responses returned by the Cart BE have the correct numeric type
  private parseCartResponse(cart: Cart): Cart {
    cart.discountPercent = Number(cart.discountPercent);
    cart.discountRp = Number(cart.discountRp);
    cart.orderItems.forEach((orderItem) => {
      orderItem.cartonQty = Number(orderItem.cartonQty);
      orderItem.unitQty = Number(orderItem.unitQty);
      orderItem.discountPercent = Number(orderItem.discountPercent);
      orderItem.discountRp = Number(orderItem.discountRp);
      orderItem.item.unitPrice = Number(orderItem.item.unitPrice);
      orderItem.item.packing = Number(orderItem.item.packing);
    });

    return cart;
  }

  async getCart({ token }: RequestWithAuth<{}>): Promise<Cart> {
    const res = await this.httpService.get<Cart>(BASE_PREFIX, token);
    return this.parseCartResponse(res);
  }

  async createCart({
    token,
    cart,
  }: RequestWithAuth<UpdateCartReq>): Promise<Cart | undefined> {
    const res = await this.httpService.post<Cart, Cart | undefined>(
      BASE_PREFIX,
      token,
      cart
    );
    return res && this.parseCartResponse(res);
  }

  async updateCart({
    token,
    cart,
  }: RequestWithAuth<UpdateCartReq>): Promise<Cart | undefined> {
    const res = await this.httpService.put<Cart, Cart | undefined>(
      BASE_PREFIX,
      token,
      cart
    );
    return res && this.parseCartResponse(res);
  }
}
