import { IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import styles from "./order_row.module.css";
import { OrderItem } from "services/order/types";
import { calculateOrderItemTotal } from "services/order/utils";
import { formatRupiah } from "ui/utils/currency";
import { UnstyledLink } from "ui/base/link/unstyled_link";
import { Media } from "ui/base/media/media";
import { NumberInput } from "ui/base/number_input/number_input";
import { Item } from "services/product/types";

export const OrderRow = ({
  orderItem,
  onRemoveItem,
  updateItemInCart,
}: {
  orderItem: OrderItem;
  onRemoveItem?: () => void;
  updateItemInCart?: (item: Item, quantity: number) => void;
}) => {
  const { item, cartonQty, discountRp, discountPercent, notes } = orderItem;
  const {
    itemId,
    imageUrl,
    displayId,
    title,
    unitPrice,
    packing,
    alternateImageUrls,
  } = item;

  const getDescription = () => {
    const qtyDesc = `Quantity: ${cartonQty} CTN x ${formatRupiah(
      (unitPrice ?? 0) * packing
    )}`;
    const discountRpDesc = discountRp
      ? `Pot. Harga (Rp) / ${item.unit}: ${formatRupiah(discountRp)}`
      : "";
    const discountPercentDesc = discountPercent
      ? `Diskon (%): ${discountPercent}`
      : "";

    return [qtyDesc, discountRpDesc, discountPercentDesc]
      .filter(Boolean)
      .join("\n");
  };

  return (
    <div className={styles.orderRow}>
      <div className={styles.image}>
        <Media
          src={imageUrl ?? ""}
          alt={title}
          type="image"
          alternativeUrls={alternateImageUrls}
        />
      </div>
      <div className={styles.description}>
        <div className={styles.itemDescription}>
          <UnstyledLink to={`/${itemId}`}>
            <Typography variant="subtitle1">{displayId}</Typography>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
            <div style={{ display: "flex", gap: 16 }}>
              <Typography
                variant="subtitle2"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {getDescription()}
              </Typography>
            </div>
            {notes && (
              <div>
                <Typography variant="subtitle2">Notes: {notes}</Typography>
              </div>
            )}
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {formatRupiah(calculateOrderItemTotal(orderItem))}
            </Typography>
          </UnstyledLink>
          <NumberInput
            size="small"
            value={cartonQty}
            onChange={(val) => updateItemInCart?.(item, val)}
          />
        </div>
        <div>
          <IconButton aria-label="delete" size="large" onClick={onRemoveItem}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
