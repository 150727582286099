import { observer } from "mobx-react";
import { BuildPage } from "pages/build/page/build_page";
import { BuildPagePresenter } from "pages/build/page/build_page_presenter";
import { BuildPageStore } from "pages/build/page/build_page_store";
import { BuildPageType } from "pages/build/page/types";
import { HttpCustomerService } from "services/customer/http_customer_service";
import { HttpClient } from "services/http/http_client";
import { HttpProductService } from "services/product/http_product_service";
import { useErrorHandler } from "react-error-boundary";

export type BuildPageProps = {
  page: BuildPageType;
};

export const installBuildPage = ({
  clearCachedItemList,
}: {
  clearCachedItemList: () => void;
}): {
  BuildPage: React.ComponentType<BuildPageProps>;
} => {
  const productService = new HttpProductService(new HttpClient());
  const customerService = new HttpCustomerService(new HttpClient());
  const store = new BuildPageStore();
  const presenter = new BuildPagePresenter(productService, customerService);

  const BuildPageImpl = observer(({ page }: BuildPageProps) => {
    const handleError = useErrorHandler();
    return (
      <BuildPage
        page={page}
        loading={store.loading}
        notification={store.notification}
        closeNotification={() => presenter.closeNotification(store)}
        onBuild={(token) => {
          presenter.buildList(store, page, token).catch((e) => handleError(e));
          clearCachedItemList();
        }}
      />
    );
  });

  return { BuildPage: BuildPageImpl };
};
