import { Typography } from "@mui/material";
import React from "react";
import { Item } from "services/product/types";
import { Media } from "ui/base/media/media";
import { formatRupiah } from "ui/utils/currency";
import styles from "./item_card.module.css";

export const ItemCard = ({ item }: { item: Item }) => {
  return (
    <div className={styles.card}>
      <div className={styles.image}>
        <Media
          src={item.imageUrl ?? ""}
          alt={item.title}
          type="image"
          alternativeUrls={item.alternateImageUrls}
        />
      </div>
      <div className={styles.details}>
        <Typography variant="body2">{item.displayId}</Typography>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {item.title}
        </Typography>
        <div className={styles.packing}>
          <Typography variant="body1">
            {item.packing} {item.unit} / CTN
          </Typography>
          {item.unitPrice && (
            <Typography variant="body1" sx={{ fontWeight: 700 }}>
              {formatRupiah(item.unitPrice)} {item.unit && `/ ${item.unit}`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
