import { ProductPage } from "pages/product/page/product_page";
import { observer } from "mobx-react-lite";
import { installOrderForm } from "pages/product/order_form/install";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { OrderItem } from "services/order/types";
import { HttpProductService } from "services/product/http_product_service";
import { HttpClient } from "services/http/http_client";
import { LoadingPage } from "pages/loading/loading_page";
import { useAppContext } from "routes/app_context";
import { useErrorHandler } from "react-error-boundary";

export type ProductPageProps = {
  findItemInCart?: (itemId: string) => OrderItem | undefined;
  addToCart?: (orderItem: OrderItem) => void;
};

export const installProductPage = (): {
  ProductPage: React.ComponentType<ProductPageProps>;
} => {
  const { OrderForm } = installOrderForm();
  const productService = new HttpProductService(new HttpClient());

  const ProductPageImpl = observer(
    ({ findItemInCart, addToCart }: ProductPageProps) => {
      const { itemId } = useParams<{ itemId: string }>();
      const [orderItem, setOrderItem] = useState<OrderItem | undefined>();
      const { user } = useAppContext();
      const handleError = useErrorHandler();

      if (!itemId) {
        throw new Error("Could not find item with id " + itemId);
      }

      useEffect(() => {
        async function getOrderItem(itemId: string) {
          // Try to find the order item from the user's cart first before starting fresh
          let orderItem = findItemInCart?.(itemId);
          if (orderItem === undefined || !orderItem.item.mediaObjs) {
            try {
              const item = await productService.getItem({
                itemId,
                token: user?.token ?? "",
              });
              if (!item) {
                throw new Error("Item not found!");
              }
              orderItem = {
                cartonQty: 0,
                unitQty: 0,
                discountPercent: 0,
                discountRp: 0,
                ...orderItem,
                item,
              };
            } catch (e) {
              handleError(e);
            }
          }
          setOrderItem(orderItem);
        }
        getOrderItem(itemId);
      }, [findItemInCart, itemId, user, handleError]);

      return orderItem ? (
        <ProductPage
          orderItem={orderItem}
          OrderForm={OrderForm}
          addToCart={addToCart}
        />
      ) : (
        <LoadingPage />
      );
    }
  );

  return { ProductPage: ProductPageImpl };
};
